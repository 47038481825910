import { useTranslation } from "react-i18next";

import { Card } from "@/components/Card";

import walletMockEs from "@/assets/Img/wallet-sendola-es.png";
import walletMockEn from "@/assets/Img/wallet-sendola-en.png";
import PaymentMethodEs from "@/assets/Img/payment-method-es.png";
import PaymentMethodEn from "@/assets/Img/payment-method-en.png";
import Plaid from "@/assets/Img/plaid.png";


import "./styles.css";

export const StickyCards = () => {
  const [t] = useTranslation("global");
  const { i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <div className="flex flex-col gap-8 w-full pb-10 lg:pb-10 max-w-screen-sm 2xl:max-w-screen-md">
      <div className="sticky pt-4 top-4 w-full pb-64 md:pb-96 md:h-full">
        <h2
          className="font-semibold lg:text-xl"
          dangerouslySetInnerHTML={{ __html: t("Home.Section2.Title") }}
        />
        <p
          className="text-sm text-purple-700 lg:text-base"
          dangerouslySetInnerHTML={{ __html: t("Home.Section2.Subtitle") }}
        />
      </div>
      <div className="flex flex-col pb-10 items-end -mt-64 md:-mt-96">
        <Card
          className="!bg-indigo-500 text-white min-w-80 h-64 sm:h-60 md:h-80 w-full overflow-hidden sticky top-[140px] xs:top-[120px] md:top-[100px]"
          noPadding
          rest
        >
          <div className="flex flex-col h-full">
            <p className="px-4 pt-4 text-lg leading-tight lg:text-2xl	">
              {t("Cards.Card1.Title")}
            </p>
            <div className="flex items-start h-full">
              <div className="w-[60%] pl-4 leading-tight">
                <img className="my-4 max-w-24 lg:h-9" src={Plaid} alt="Plaid" />
                <p
                  className="text-[10px] md:text-base"
                  dangerouslySetInnerHTML={{
                    __html: t("Cards.Card1.Paragraph"),
                  }}
                />
              </div>
              <div
                className="w-[40%] lg:w-[38%] h-full max-sm:mr-2 background-class-card1"
                style={{
                  backgroundImage: `url(${lang === "en" ? PaymentMethodEn : PaymentMethodEs})`,
                  backgroundSize: "cover",
                  backgroundPosition: "-8px 3px",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </div>
          </div>
        </Card>

        <Card
          className="!bg-pink-500 text-white min-w-80 h-64 sm:h-64 md:h-80 w-full overflow-hidden sticky top-[140px] xs:top-[120px] md:top-[100px] translate-y-[40px] md:translate-y-[50px]"
          noPadding
          rest
        >
          <div className="flex flex-col h-full">
            <p className="px-4 pt-4 text-lg leading-tight lg:text-2xl">
              {t("Cards.Card2.Title")}
            </p>
            <div className="flex items-start h-full">
              <div
                className="w-[45%] h-full place-self-end background-class-card2"
                style={{
                  backgroundImage: `url(${lang === "en" ? walletMockEn : walletMockEs})`,
                  backgroundSize: "cover",
                  backgroundPosition: "0 20px",
                  backgroundRepeat: "no-repeat",
                }}
              />
              <div className="text-[10px] md:text-base w-[55%] pr-2 pb-2 pt-4">
                <p className="md:font-semibold leading-tight">
                  {t("Cards.Card2.Paragraph")}
                </p>
                <ul className="list-disc pl-4 text-[10px] md:text-sm mt-2">
                  <li>{t("Cards.Card2.Bullet1")}</li>
                  <li>{t("Cards.Card2.Bullet2")}</li>
                  <li>{t("Cards.Card2.Bullet3")}</li>
                </ul>
              </div>
            </div>
          </div>
        </Card>

        {/* <Card
          className="!bg-indigo-900 text-white min-w-80 h-56 sm:h-60 md:h-80 w-full overflow-hidden sticky top-[140px] xs:top-[120px] md:top-[100px] translate-y-[90px] md:translate-y-[100px]"
          noPadding
          rest
        >
          <div className="flex flex-col h-full">
            <p className="px-4 pt-4 text-lg leading-tight lg:text-2xl">
              {t("Cards.Card4.Title")}
            </p>
            <div className="flex items-start gap-2 justify-center md:justify-around mt-4">
              <img
                className="w-[30%] my-2 max-sm:w-20 lg:max-h-18 pl-4 h-container"
                src={lang === "es" ? MiCel : Myphone}
                alt="Mi cel"
              />
              <img
                className="w-[30%] md:w-[25%] translate-y-2 -mt-2 place-self-end object-contain max-w-xs"
                src={lang === "es" ? MicelScreenEs : MicelScreenEn}
                alt="Phone"
              />
              <p
                className="text-[10px] md:text-base w-[40%] leading-tight mt-auto mb-8 md:mb-20 xl:mb-32 mr-2"
                dangerouslySetInnerHTML={{
                  __html: t("Cards.Card4.Paragraph"),
                }}
              />
            </div>
          </div>
        </Card> */}
      </div>
    </div>
  );
};
