import classNames from "classnames";
import { useEffect, useState } from "react";

interface SelectorOption {
  label: string;
  value: string;
}

interface SelectorProps {
  options: SelectorOption[];
  onChange?: (newValue: string) => void;
}

export const Selector = ({ options, onChange }: SelectorProps) => {
  const [selectedOption, setSelectedOption] = useState<number>(0);

  useEffect(() => {
    if (onChange) {
      onChange(options[selectedOption].value);
    }
  }, [selectedOption]);

  return (
    <div className="relative flex gap-4 h-10 justify-between items-center w-full rounded-full bg-gray-100">
      {options.map((option, index) => {
        return (
          <p
            key={`selector-option-${index}`}
            onClick={() => setSelectedOption(index)}
            className="w-full flex items-center justify-center h-full px-2 py-2 text-center text-xs md:text-sm font-semibold text-gray-500 overflow-hidden text-ellipsis cursor-pointer select-none"
          >
            {option.label}
          </p>
        );
      })}
      <p
        className={classNames(
          "flex items-center justify-center absolute transition-all top-0 h-full text-white",
          "text-xs md:text-sm text-ellipsis font-semibold rounded-full px-1 bg-gradient-to-tr from-purple-700 to-indigo-600 cursor-default select-none"
        )}
        style={{
          width: `${(100 / options?.length || 1).toFixed(2)}%`,
          left: `${Math.floor(100 / options?.length || 1) * selectedOption}%`,
        }}
      >
        {options[selectedOption].label}
      </p>
    </div>
  );
};
