import { useEffect, useState } from "react";
import {
  Bars3Icon,
  ChevronDownIcon,
  CreditCardIcon,
} from "@heroicons/react/24/outline";
import sendolaLogo from "@/assets/Img/logo-sendola.png";
import MXFlag from "@/assets/Img/Flags/MXIcon.png";
import USFlag from "@/assets/Img/Flags/USIcon.png";
import WhatsappIcon from "@/assets/Icons/whatsapp.svg?react";
import { LanguageCard } from "@/components/LanguageCard";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Cookies from "universal-cookie";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import { ensureSingleCookie } from "@/utilities/Cookies";

const menuItem =
  "flex w-full gap-2 items-center py-3 text-white hover:text-purple-300 cursor-pointer border-b-[1px] border-b-gray-600 last:border-0";

export const Header = () => {
  const [t] = useTranslation("global");
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [menuTouched, setMenuTouched] = useState<boolean>(false);
  const [languageTouched, setLanguageTouched] = useState<boolean>(false);
  const [showLanguageBar, setShowLanguageBar] = useState<boolean>(false);
  const [scrollDirection, setScrollDirection] = useState<"down" | "up">("up");
  const [visible, setVisible] = useState(true);

  const cookies = new Cookies();
  const lang: string = cookies.get("lang") || "es";

  ensureSingleCookie("lang");

  const ref = useOutsideClick(() => {
    if (menuOpen) setMenuOpen(false);
  });

  useEffect(() => {
    if (!lang) setShowLanguageBar(true);
  }, [lang]);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction);
        setVisible(direction === "up");
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener("scroll", updateScrollDirection);

    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection]);

  useEffect(() => {
    if (!visible) {
      setMenuOpen(false);
    }
  }, [visible]);

  return (
    <>
      <div
        className={classNames(
          "z-20 sticky top-0 transition-all duration-200 ease-in",
          {
            "-translate-y-20": !visible,
            "translate-y-0": visible,
          }
        )}
      >
        <div
          className={
            "w-full h-16 flex justify-between bg-gradient-to-r from-black from-60% to-[#6739dc] px-4 lg:px-10 py-2 items-center"
          }
        >
          <div
            className={classNames(
              "h-10 flex gap-2 text-white items-center bg-black lg:hover:bg-gray-800 rounded px-2 -ml-2 cursor-pointer",
              {
                "bg-gray-800": languageTouched,
              }
            )}
            onTouchStart={() => setLanguageTouched(true)}
            onTouchEnd={() => setLanguageTouched(false)}
            onClick={() => setShowLanguageBar(true)}
          >
            <img
              className="w-6 h-6 rounded-full object-cover"
              src={currentLanguage === "en" ? USFlag : MXFlag}
              alt="Language flag"
            />
            <p>{currentLanguage.toUpperCase()}</p>
            <ChevronDownIcon className="w-4 h-4" />
          </div>
          <Link to="/">
            <img
              className="h-10 w-24 object-contain mr-4"
              src={sendolaLogo}
              alt="Sendola's Logo"
            />
          </Link>
          <div ref={ref} className="w-16 flex justify-end">
            <Bars3Icon
              className={classNames(
                "w-10 h-10 rounded text-white cursor-pointer lg:hover:bg-purple-950 p-2",
                {
                  "bg-purple-950": menuTouched,
                }
              )}
              onClick={() => setMenuOpen(!menuOpen)}
              onTouchStart={() => setMenuTouched(true)}
              onTouchEnd={() => setMenuTouched(false)}
            />
          </div>
        </div>

        <div
          className={classNames(
            "absolute overflow-hidden transition-all w-max min-w-60 rounded-b-lg flex-col bg-black/85 shadow-lg px-4 top-16 right-0 z-20 box-border",
            {
              "max-h-80": menuOpen,
              "max-h-0": !menuOpen,
            }
          )}
        >
          <Link to="/banner-bank" className={menuItem}>
            <CreditCardIcon className="w-5 h-5" />
            <p className="text-sm lg:text-base">{t("Header.BannerBank")}</p>
          </Link>

          <a
            href="https://api.whatsapp.com/send?phone=18325251941&text=Hola!"
            className={menuItem}
          >
            <WhatsappIcon className="w-5 h-5" />
            <p className="text-sm lg:text-base">{t("Header.Assistant")}</p>
          </a>
        </div>
      </div>

      <LanguageCard
        show={showLanguageBar}
        onClose={() => setShowLanguageBar(false)}
      />
    </>
  );
};
