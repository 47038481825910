import { useTranslation } from "react-i18next";
import { AtmLocator } from "@/components/AtmLocator";
import ReactHelmet from "react-helmet";

export const AccelNetwork = () => {
  const [t] = useTranslation("global");

  return (
    <>
      <ReactHelmet>
        <title>Accel ATM network | Sendola</title>
      </ReactHelmet>
      <AtmLocator
        iframeSrc="https://accelnetworklocator.wave2.io/index.aspx?ref=xxx/bddfmofuxpsl/dpn&"
        disclaimer={t("AtmNetwork.Disclaimer")}
      />
    </>
  );
};
