import { useTranslation } from "react-i18next";
import CoopLogo from "@/assets/Img/coop-logo.png";
import MoneyPassLogo from "@/assets/Img/moneypass-logo.png";
import AccelLogo from "@/assets/Img/accel-logo.png";
import ReactHelmet from "react-helmet";
import { Link } from "react-router-dom";

export const AtmNetwork = () => {
  const [t] = useTranslation("global");

  return (
    <>
      <ReactHelmet>
        <title>ATM network | Sendola</title>
      </ReactHelmet>

      <div className="flex flex-col items-center gap-10 w-full min-h-full bg-[#1b1c1e] text-white px-6 pt-6 pb-40">
        <div className="w-full flex flex-col gap-4 justify-center items-center h-28 lg:h-40">
          <h1 className="text-xl font-semibold md:text-2xl text-center">
            {t("AtmNetwork.MainTitle")}
          </h1>
          <h2 className="text-lg md:text-2xl text-center">
            {t("AtmNetwork.MainSubtitle")}
          </h2>
        </div>

        <div className="w-full max-w-screen-xl flex flex-col gap-10 items-center justify-center md:flex-row">
          <Link
            to="/coop-network"
            className="p-8 rounded-2xl transition-all hover:shadow-xl hover:shadow-black/35 cursor-pointer bg-[#1f2022]"
          >
            <img
              className="w-40 h-40 object-contain"
              src={CoopLogo}
              alt="CO-OP network Logo"
            />
          </Link>

          <Link
            to="/moneypass-network"
            className="p-8 rounded-2xl transition-all hover:shadow-xl hover:shadow-black/35 cursor-pointer bg-[#1f2022]"
          >
            <img
              className="w-40 h-40 object-contain"
              src={MoneyPassLogo}
              alt="MoneyPass network Logo"
            />
          </Link>

          <Link
            to="/accel-network"
            className="p-8 rounded-2xl transition-all hover:shadow-xl hover:shadow-black/35 cursor-pointer bg-[#1f2022]"
          >
            <img
              className="w-40 h-40 object-contain"
              src={AccelLogo}
              alt="Accel network Logo"
            />
          </Link>
        </div>

        <div className="w-full flex flex-col gap-20 items-center mt-4">
          <p className="text-center text-sm max-w-screen-md">
            {t("AtmNetwork.Callout")}
          </p>
          <p className="text-center text-xs max-w-screen-md">
            {t("AtmNetwork.Disclaimer")}
          </p>
        </div>
      </div>
    </>
  );
};
