import Cookies from "universal-cookie";

const cookies = new Cookies();

export function getCookiesByName(name: string): string[] {
  const allCookies = document.cookie.split('; ');
  return allCookies.filter(cookie => cookie.startsWith(`${name}=`));
}

export function ensureSingleCookie(name: string) {
  const cookiesWithSameName = getCookiesByName(name);

  if (cookiesWithSameName.length > 1) {
    const [firstCookie] = cookiesWithSameName;
    const firstValue = firstCookie.split('=')[1];

    cookiesWithSameName.forEach(cookie => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const cookieValue = cookie.split('=')[1];
      cookies.remove(name);
    });

    cookies.set(name, firstValue);
  }
}