import { useTranslation } from "react-i18next";

interface AtmLocatorProps {
  iframeSrc: string;
  disclaimer?: string;
}

export const AtmLocator = ({ iframeSrc, disclaimer }: AtmLocatorProps) => {
  const [t] = useTranslation("global");

  return (
    <div className="flex flex-col gap-10 w-full bg-[#1b1c1e] text-white px-6 pt-6 pb-40">
      <div className="w-full flex flex-col gap-4 justify-center items-center h-28 lg:h-40">
        <h1 className="text-xl font-semibold md:text-2xl text-center">
          {t("AtmNetwork.SecondaryTitle")}
        </h1>
        <h2 className="md:text-lg text-center">
          {t("AtmNetwork.SecondarySubtitle")}
        </h2>
      </div>

      <div className="w-full flex items-center justify-center">
        <iframe className="w-[500px] lg:w-[800px] h-[700px]" src={iframeSrc} />
      </div>

      <div className="w-full flex flex-col gap-20 items-center mt-4">
        <p className="text-center text-sm max-w-screen-sm 2xl:max-w-screen-md">
          {disclaimer}
        </p>
      </div>
    </div>
  );
};
