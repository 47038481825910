import { useEffect, useRef, useState } from "react";

import { CopyTextToClipboard } from "@/utilities/CopyTextToClipboard";

interface AccordionItemProps {
  title: string;
  text: string;
  url?: string;
  typeList?: "list-disc" | "list-decimal" | "list-none"  | undefined;
  list?: Array<any>;
  text2: string | undefined;
  defaultOpenId?: string;
  questionId?: string;
}

const AccordionItem = ({
  title,
  text,
  url,
  typeList,
  list,
  text2,
  defaultOpenId,
  questionId
}: AccordionItemProps) => {

  const [openSection, setOpenSection] = useState<number | null>(null);
  const [isCopy, setIsCopy] = useState<boolean | null>(false);
  const accordionRef = useRef<HTMLDivElement | null>(null);

  const toggleSection = (index: number) => {
    setOpenSection(openSection === index ? null : index);
  };

  const onCopyText = () => {
    setIsCopy(true);
    CopyTextToClipboard(url);

    setTimeout(() => {
      setIsCopy(false);
    }, 1000);
  }

  useEffect(() => {
    if (defaultOpenId === questionId) {
      setOpenSection(1);
      accordionRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [defaultOpenId, questionId]);

  return (
    <div id="accordion-flush" data-accordion="collapse" data-inactive-classes="text-gray-500 dark:text-gray-400" ref={accordionRef}>
      <h2 id="accordion-flush-heading-1" className="text-white">
        <button
          type="button"
          className={`flex items-center justify-between w-full py-5 font-medium rtl:text-right text-white gap-3 ${openSection ? '' : 'border-b border-fuchsia-400 dark:border-fuchsia-400'}`}
          data-accordion-target="#accordion-flush-body-1"
          aria-expanded={openSection === 1}
          aria-controls={`accordion-flush-body-${questionId}`}
          onClick={() => toggleSection(1)}
        >
          <span className="text-white text-left">{title}</span>
          <svg data-accordion-icon className={`w-3 h-3 shrink-0 ${openSection ? '' : 'rotate-180 '}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5"/>
          </svg>

        </button>
      </h2>
      <div id={`accordion-flush-body-${questionId}`} className={`${openSection ? 'block' : 'hidden'}`} aria-labelledby={`accordion-flush-heading-${questionId}`}>
        <div className="py-5 border-b border-fuchsia-400 text-white">
          <p className="mb-2 text-white text-sm" dangerouslySetInnerHTML={{ __html: text }} />
          {list &&
            <ul className={`text-white text-sm ml-8 my-6 ${typeList}`}>
              {list &&
                list.map((point, i) => {
                  return (
                    <li key={i} className="my-1" dangerouslySetInnerHTML={{ __html: point }} />
                  )
                })
              }
            </ul>
          }
          {text2 &&
            <p className="text-white text-sm mt-2" dangerouslySetInnerHTML={{ __html: text2 }} />
          }
          <button className="font-medium text-white hover:text-neutral-300 hover:underline" onClick={onCopyText}>
            {isCopy ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 mt-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
              </svg> :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 mt-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
              </svg>
            }
          </button>
        </div>
      </div>

    </div>
  )
}

export default AccordionItem
