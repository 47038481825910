import { useTranslation } from "react-i18next";
import { AtmLocator } from "@/components/AtmLocator";
import ReactHelmet from "react-helmet";

export const MoneyPassNetwork = () => {
  const [t] = useTranslation("global");

  return (
    <>
      <ReactHelmet>
        <title>Money Pass ATM Locator | Sendola</title>
      </ReactHelmet>
      <AtmLocator
        iframeSrc="https://moneypass.com/atm-locator.html"
        disclaimer={t("AtmNetwork.Disclaimer")}
      />
    </>
  );
};
