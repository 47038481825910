import { ReactNode, useRef, useState } from "react";
import classNames from "classnames";

import { Card } from "@/components/Card";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

interface CarouselItem {
  id: string;
  content: ReactNode;
}

interface CarouselProps {
  items: CarouselItem[];
  showDots?: boolean;
}

export const Carousel = ({ items, showDots = true }: CarouselProps) => {
  const [visibleItem, setVisibleItem] = useState<string>(items?.[0]?.id || "");
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (scrollableContainerRef?.current && showDots) {
      const offsetX = scrollableContainerRef.current.scrollLeft;
      const itemIndex = Math.round(
        offsetX / scrollableContainerRef.current.clientWidth
      );
      setVisibleItem(items?.[itemIndex]?.id || "");
    }
  };

  const scrollLeft = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollBy({
        left: -300,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    }
  };

  const handleDotClick = (index: number) => {
    if (scrollableContainerRef.current) {
      const scrollLeftPosition = index * scrollableContainerRef.current.clientWidth;

      scrollableContainerRef.current.scrollTo({
        left: scrollLeftPosition,
        behavior: "smooth",
      });

      setVisibleItem(items[index].id);
    }
  };

  return (
    <div className="justify-center w-full flex flex-col gap-4 md:relative hide-scroll">
      <div
        ref={scrollableContainerRef}
        onScroll={handleScroll}
        className="w-full flex gap-2 items-center overflow-x-scroll snap-x snap-mandatory -my-2 -mx-4 px-4 py-2"
      >
        <button
          onClick={scrollLeft}
          className="max-sm:hidden absolute left-0 z-10 bg-gray-800 opacity-75 text-white p-2 rounded-full hover:bg-gray-600"
        >
          <ChevronLeftIcon className="w-5 h-5"/>
        </button>
        {items.map((item, index) => {
          if (!item.content) return;

          return (
            <Card
              key={`${item.id}-${index}`}
              className="w-full min-w-full h-full flex-shrink-0 snap-center p-2"
            >
              {item.content}
            </Card>
          );
        })}
        <button
          onClick={scrollRight}
          className="max-sm:hidden absolute right-0 z-10 bg-gray-800 opacity-75 text-white p-2 rounded-full hover:bg-gray-600"
        >
          <ChevronRightIcon className="w-5 h-5"/>
        </button>
      </div>
      {showDots && (
        <div className="flex gap-1 justify-center">
          {items.map((item, index) => {
            if (!item?.id) return;

            return (
              <div
                key={`${item.id}-${index}-dot`}
                onClick={() => handleDotClick(index)}
                className={classNames("w-2 h-2 rounded-full", {
                  "bg-gray-400": visibleItem !== item.id,
                  "bg-purple-500": visibleItem === item.id,
                })}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
