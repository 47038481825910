import classNames from "classnames";
import { ReactNode } from "react";

interface CardProps {
  id?: string;
  rest?: boolean;
  raised?: boolean;
  noPadding?: boolean;
  className?: string;
  rounded?: string;
  children?: ReactNode;
}

export const Card = ({
  id,
  rest,
  raised,
  noPadding = false,
  className,
  children,
  rounded = "rounded-xl"
}: CardProps) => {
  return (
    <div
      id={id}
      className={classNames(
        className,
        `bg-white transition-all ${rounded} min-w-20 min-h-20`,
        {
          "shadow shadow-gray-400": !raised && !rest,
          "shadow-lg shadow-gray-500": raised && !rest,
          "p-4": !noPadding,
        }
      )}
    >
      {children}
    </div>
  );
};
