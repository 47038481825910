import classNames from "classnames";

interface TestimonialCardProps {
  title: string;
  subtitle?: string;
  paragraph?: string;
  imageUrl?: string;
  imageAlt?: string;
  className?: string;
}

export const TestimonialCard = ({
  title,
  subtitle,
  paragraph,
  imageUrl,
  imageAlt,
  className,
}: TestimonialCardProps) => {
  return (
    <div className={classNames("flex gap-4 items-start py-2", className)}>
      <div className="w-fit">
        <img
          className="h-16 w-16 rounded-full object-fill"
          src={imageUrl}
          alt={imageAlt || title}
        />
      </div>
      <div className="flex flex-col gap-2 flex-1">
        <div>
          <p className="text-sm font-semibold">{title}</p>
          <p className="text-sm">{subtitle}</p>
        </div>
        <p className="text-xs italic">”{paragraph}”</p>
      </div>
    </div>
  );
};
