import { useTranslation } from "react-i18next";
import MXIcon from "@/assets/Img/Flags/MXIcon.png";
import USIcon from "@/assets/Img/Flags/USIcon.png";
import Cookies from "universal-cookie";
import classNames from "classnames";
import { Button } from "@/components/Button";

interface LanguageCardProps {
  show: boolean;
  onClose: () => void;
}

interface LanguageItem {
  name: string;
  icon: string;
  value: string;
}

export const LanguageCard = ({ show, onClose }: LanguageCardProps) => {
  const cookies = new Cookies();
  const [t, i18n] = useTranslation("global");

  const supportedLanguages: LanguageItem[] = [
    {
      name: t("Languages.Spanish"),
      icon: MXIcon,
      value: "es",
    },
    {
      name: t("Languages.English"),
      icon: USIcon,
      value: "en",
    },
  ];

  const handleChange = (value: string) => {
    cookies.set("lang", value);
    i18n.changeLanguage(value);
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      {show && (
        <div
          className="w-full h-full z-40 fixed top-0 bg-black/30"
          onClick={onClose}
        />
      )}
      <div
        className={classNames(
          "w-full flex flex-col items-center justify-center z-40 fixed bottom-0 px-6",
          "rounded-t-2xl bg-white shadow-xl shadow-gray-400 transition-all ease-out",
          {
            "max-h-0 py-0": !show,
            "max-h-60 py-6": show,
          }
        )}
      >
        <div className="w-full h-full flex flex-col gap-4 overflow-hidden">
          <p className="text-lg font-semibold">{t("Languages.SelectTitle")}</p>
          {supportedLanguages.map((language, index) => {
            return (
              <Button
                key={`${language.name}-${index}`}
                size="sm"
                variant="secondary"
                color="indigo"
                block
                onClick={() => handleChange(language.value)}
              >
                <div className="w-full flex items-center gap-2 justify-center">
                  <img
                    className="w-6 h-6"
                    src={language.icon}
                    alt={`${language.name} flag icon`}
                  />
                  <span className="text-black">{language.name}</span>
                </div>
              </Button>
            );
          })}
        </div>
      </div>
    </>
  );
};
